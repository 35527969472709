import { Link } from '@remix-run/react'

import { Icon } from './ui/icon.tsx'
import { Marquee } from './ui/marquee.tsx'

export function Brands({ totalNumberOfCars }: { totalNumberOfCars: number }) {
	return (
		<section className="bg-[#eaebf0] py-12 dark:bg-muted/30 sm:py-16 md:-mx-4 lg:py-20 xl:py-24">
			<div className="mx-auto max-w-6xl px-2 lg:px-8">
				<Marquee
					className="gap-[3rem] pb-8 text-gray-700 dark:text-white md:py-4 md:pb-0"
					innerClassName="md:gap-[3rem] md:[--gap:3rem] gap-[2rem] --gap:2rem"
					fade={true}
					pauseOnHover={false}
				>
					{/*Audi*/}
					<div className="flex items-center justify-center sm:w-[150px]">
						<Link
							prefetch="intent"
							to="/carros?marca=audi"
							aria-label="Veja os carros da marca Audi"
						>
							<Icon
								name="audi"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*BYD*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=byd"
							aria-label="Veja os carros da marca BYD"
						>
							<Icon
								name="byd"
								className="text-8xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*BMW*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=bmw"
							aria-label="Veja os carros da marca BMW"
						>
							<Icon
								name="bmw"
								className="text-8xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Chevrolet*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=chevrolet"
							aria-label="Veja os carros da marca Chevrolet"
						>
							<Icon
								name="chevrolet"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Volvo*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=volvo"
							aria-label="Veja os carros da marca Volvo"
						>
							<Icon
								name="volvo"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Jaguar*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=jaguar"
							aria-label="Veja os carros da marca Jaguar"
						>
							<Icon
								name="jaguar"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Tesla*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=tesla"
							aria-label="Veja os carros da marca Tesla"
						>
							<Icon
								name="tesla"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Porsche*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=porsche"
							aria-label="Veja os carros da marca Porsche"
						>
							<Icon
								name="porsche"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Renault*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=renault"
							aria-label="Veja os carros da marca Renault"
						>
							<Icon
								name="renault"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Nissan*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=nissan"
							aria-label="Veja os carros da marca Nissan"
						>
							<Icon
								name="nissan"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Mini*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=mini"
							aria-label="Veja os carros da marca Mini"
						>
							<Icon
								name="mini"
								className="text-9xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
					{/*Mercedez*/}
					<div className="flex w-[150px] items-center justify-center">
						<Link
							prefetch="intent"
							to="/carros?marca=mercedes-benz"
							aria-label="Veja os carros da marca Mercedes-Benz"
						>
							<Icon
								name="mercedez"
								className="text-7xl transition duration-300 ease-in-out hover:scale-110 hover:text-[#f58b14]"
							/>
						</Link>
					</div>
				</Marquee>
				<div className="mx-auto max-w-5xl text-center md:mb-20">
					<p className="mt-6 text-3xl font-medium tracking-tight lg:text-5xl lg:leading-[54px]">
						Encontre o carro elétrico dos seus sonhos entre os mais de{' '}
						<span className="font-bold text-[#f58b14]">
							{totalNumberOfCars}
						</span>{' '}
						anúncios
					</p>
				</div>
			</div>
		</section>
	)
}
