import { Link } from '@remix-run/react'
import { Check } from 'lucide-react'
import { Button } from '#app/components/ui/button.tsx'

export function MapMain() {
	return (
		<section
			id="mapa"
			className="bg-[#eaebf0] px-4 py-16 dark:bg-muted/30 md:-mx-4 lg:px-8"
		>
			<div className="mx-auto max-w-6xl text-center">
				<h2 className="text-base font-bold text-[#f58b14] md:text-lg">
					Carregadores
				</h2>{' '}
				<p className="mt-6 text-2xl font-bold capitalize tracking-tight text-primary dark:text-primary sm:text-4xl lg:text-5xl">
					Mapa de Carregadores
				</p>{' '}
				<p className="mx-auto mt-4 max-w-3xl text-center text-base font-normal text-muted-foreground">
					O mapa de estações de carregamento de carros elétricos mais completo
					do Brasil.
				</p>
				<div className="mx-auto mt-4 flex w-full flex-col-reverse gap-6 sm:mt-6 md:flex-row md:justify-center lg:mt-8">
					<div className="flex flex-col md:items-center md:justify-center">
						<ul className="pb-10 text-lg">
							<li className="mt-4 flex gap-1 text-left font-semibold">
								<Check className="text-[#f58b14]" />
								<span>
									Mais de <span className="font-bold text-primary">10.000</span>{' '}
									estações de carregamento
								</span>
							</li>
							<li className="mt-4 flex gap-1 text-left font-semibold">
								<Check className="text-[#f58b14]" />
								<span>
									Informações{' '}
									<span className="font-bold text-primary">atualizadas</span> de
									disponibilidade
								</span>
							</li>
							<li className="mt-4 flex gap-1 text-left font-semibold">
								<Check className="text-[#f58b14]" />
								<span>
									<span className="font-bold text-primary">Avaliações</span> dos
									últimos usuários e{' '}
									<span className="font-bold text-primary">fotos do local</span>
								</span>
							</li>
							<li className="mt-4 flex gap-1 text-left font-semibold">
								<Check className="text-[#f58b14]" />
								<span>
									<span className="font-bold text-primary">
										Conectores, preço, potência
									</span>{' '}
									e muito mais
								</span>
							</li>
						</ul>

						<Link prefetch="intent" to="/mapa">
							<Button className="font-bold capitalize">
								ver mapa de carregadores
							</Button>
						</Link>
					</div>
					<img
						loading="lazy"
						className="aspect-square w-full rounded-lg md:max-w-[400px]"
						src="/img/mapa-main.webp"
						alt="Mapa de carregadores de carros elétricos."
					/>
				</div>
			</div>
		</section>
	)
}
