import { Form } from '@remix-run/react'

import { ComboboxWithData } from '#app/components/comboboxwithdata.tsx'
import { Button } from '#app/components/ui/button.tsx'
import { Label } from '#app/components/ui/label.tsx'

export function Selectors({
	makers,
	models,
	states,
	selectedMaker,
	onMakerChange,
	selectedModel,
	onModelChange,
	selectedState,
	onStateChange,
}: any) {
	return (
		<div>
			<Form className="flex space-x-1" method="post">
				{/* Marca */}
				<span className="flex flex-col text-white">
					<Label htmlFor="marca">Marca</Label>
					<ComboboxWithData
						id="marca"
						fullData={makers}
						selectedValue={selectedMaker}
						onValueChange={onMakerChange}
						ariaLabel="Selecione a marca"
					/>
					<input type="hidden" name="marca" value={selectedMaker} />
				</span>
				{/* Modelo */}
				<span className="flex flex-col text-white">
					<Label htmlFor="modelo">Modelo</Label>
					<ComboboxWithData
						id="modelo"
						fullData={models}
						selectedValue={selectedModel}
						onValueChange={onModelChange}
						ariaLabel="Selecione o modelo"
					/>
					<input type="hidden" name="modelo" value={selectedModel} />
				</span>
				{/* Localização */}
				<span className="flex flex-col text-white">
					<Label htmlFor="localização">Localização</Label>
					<ComboboxWithData
						id="localização"
						fullData={states}
						selectedValue={selectedState}
						onValueChange={onStateChange}
						ariaLabel="Selecione a localização"
					/>
					<input type="hidden" name="localizacao" value={selectedState} />
				</span>

				<span className="flex flex-col">
					<Label className="invisible" htmlFor="enviar">
						Enviar
					</Label>
					<Button
						type="submit"
						id="enviar"
						className="animate-gradient-x border-0 bg-gradient-to-r from-purple-600 via-pink-500 to-yellow-500 text-white hover:opacity-80"
					>
						Buscar
					</Button>
				</span>
			</Form>
		</div>
	)
}
