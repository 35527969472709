import { Link } from '@remix-run/react'

export function Tools() {
	return (
		<section
			id="utilidades"
			className="bg-[#fbfaf8] px-4 py-16 dark:bg-muted md:-mx-4 lg:px-8"
		>
			<div className="mx-auto max-w-6xl text-center">
				<h2 className="text-base font-bold text-[#f58b14] md:text-lg">
					Utilidades
				</h2>{' '}
				<p className="mt-6 text-2xl font-bold tracking-tight text-primary dark:text-primary sm:text-4xl lg:text-5xl">
					Entre na revolução elétrica!
				</p>{' '}
				<p className="mt-4 text-base font-normal text-muted-foreground">
					Confira nossos artigos e informações sobre o mundo elétrico
				</p>
				<div className="mx-auto mt-4 w-full sm:mt-6 lg:mt-8">
					<div className="grid w-full grid-cols-1 place-items-center gap-6 px-2 md:gap-10 lg:grid-cols-3">
						<Link
							prefetch="intent"
							to="quanto-tempo-demora-para-carregar-um-carro-eletrico"
							className="mt-2 text-left font-semibold tracking-tight transition duration-300 ease-in-out hover:underline md:hover:scale-105"
						>
							<img
								loading="lazy"
								className="aspect-square w-full rounded-lg md:max-w-[400px]"
								src="/img/quanto-tempo-demora.webp"
								alt="Carro elétrico carregando em um wallbox caseiro."
							/>
							<span className="font-bold">
								Quanto tempo demora para carregar um carro elétrico?
							</span>
						</Link>
						<Link
							prefetch="intent"
							to="quanto-custa-para-carregar-um-carro-eletrico"
							className="text-left font-semibold tracking-tight transition duration-300 ease-in-out md:pb-6 md:hover:scale-110"
						>
							<img
								loading="lazy"
								className="mt-2 aspect-square w-full rounded-lg md:w-[400px]"
								src="/img/quanto-custa-carregar.webp"
								alt="Carro elétrico carregando em um ponto de carregamento público."
							/>
							<span className="font-bold">
								Quanto custa para carregar um carro elétrico?
							</span>
						</Link>
						<Link
							prefetch="intent"
							to="glossario"
							className="text-left font-semibold tracking-tight transition duration-300 ease-in-out md:pb-6 md:hover:scale-110"
						>
							<img
								loading="lazy"
								className="mt-2 aspect-square w-full rounded-lg md:w-[400px]"
								src="/img/glossario.webp"
								alt="Um robô pensando em um glossário de termos elétricos."
							/>
							<span className="font-bold">Glossário Elétrico</span>
						</Link>
					</div>
				</div>
			</div>
		</section>
	)
}
