import { Link } from '@remix-run/react'
import { CircleCheck } from 'lucide-react'
import { Badge } from '#app/components/ui/badge.tsx'
import { Button } from '#app/components/ui/button.tsx'
import { Card } from '#app/components/ui/card.tsx'

export function PricingBeta() {
	return (
		<section
			id="preco"
			className="bg-[#eaebf0] px-6 py-12 dark:bg-muted/30 sm:py-16 md:-mx-4 lg:py-20 xl:py-24"
		>
			<div className="mx-auto max-w-3xl text-center">
				<h2 className="text-base font-bold text-[#f58b14] md:text-lg">
					Anunciantes
				</h2>{' '}
				<p className="mt-6 text-2xl font-bold tracking-tight text-primary sm:text-4xl lg:text-5xl">
					Anunciar
				</p>{' '}
				<p className="mt-4 text-center text-base font-normal text-muted-foreground">
					Não perca tempo anunciando seu carro elétrico junto com carros a
					combustão.
					<br />
					<span className="font-bold">
						Anuncie no ÚNICO portal exclusivo para carros elétricos do Brasil.
					</span>
				</p>
			</div>{' '}
			<div className="flex flex-col gap-6 md:flex-row md:justify-center">
				<Card className="mt-16 w-full max-w-sm rounded-lg bg-[#1a3d5e] p-6 text-white">
					<div className="text-center">
						<Badge variant="secondary" className="mb-4 bg-white text-[#1a3d5e]">
							Mais popular
						</Badge>
						<div className="mb-4 flex justify-center">
							<img
								src="/img/logo.webp"
								alt="Logo"
								className="h-16 w-16 rounded-full border border-white"
								width="64"
								height="64"
								style={{ aspectRatio: '64/64', objectFit: 'cover' }}
							/>
						</div>
						<div className="mb-4 text-lg font-bold">Crédito para anúncio</div>
						<div className="mb-4 text-4xl font-bold">R$ 99</div>
						<Link to="/usuario/carregados/carros/creditos">
						<Button className="w-full rounded-lg bg-[#219EBC] text-white">
							Anunciar
							</Button>
							</Link>
					</div>
					<div className="mt-6">
						<div className="mb-2 font-semibold">Inclui:</div>
						<ul className="list-inside space-y-4">
							<li className="flex items-start gap-1">
								<div className="flex-shrink-0">
									<CircleCheck />
								</div>
								Tempo ilimitado. Fique até vender!
							</li>
							<li className="flex items-start gap-1">
								<div className="flex-shrink-0">
									<CircleCheck />
								</div>
								Retorno para o topo da página após 30, 60 e 90 dias.
							</li>
							<li className="flex items-start gap-1">
								<div className="flex-shrink-0">
									<CircleCheck />
								</div>
								Criador de anúncio com IA. Crie o anúncio perfeito e venda
								rápido.
							</li>
							<li className="flex items-start gap-1">
								<div className="flex-shrink-0">
									<CircleCheck />
								</div>
								Destaque no único portal exclusivo para carros elétricos e
								híbridos do Brasil.
							</li>
						</ul>
					</div>
				</Card>
				<Card className="mt-10 md:mt-16 w-full bg-[#eaebf0] dark:bg-muted/30 max-w-sm rounded-lg p-6">
					<div className="text-center">
						<Badge variant="secondary" className="mb-4 bg-[#1a3d5e] text-white">
							Melhor negociação
						</Badge>	
						<div className="md:mt-[138px] mb-4 text-lg font-bold">
							Pacote de créditos para anúncios
						</div>
						<Link to="mailto:contato@carregados.com.br">
						<Button className="w-full rounded-lg bg-[#219EBC] text-white">
							Fale Conosco
							</Button>
							</Link>
					</div>
					<div className="mt-6">
						<div className="mb-2 font-semibold">
							Para descontos especiais em pacotes acima de 5 anúncios, entre em
							contato!
						</div>
					</div>
					{/*	<ul className="list-inside space-y-4">
							<li className="flex items-start gap-1">
								<div className="flex-shrink-0">
									<CircleCheck />
								</div>
								Tempo ilimitado. Fique até vender!
							</li>
							<li className="flex items-start gap-1">
								<div className="flex-shrink-0">
									<CircleCheck />
								</div>
								Retorno para o topo da página após 30, 60 e 90 dias.
							</li>
							<li className="flex items-start gap-1">
								<div className="flex-shrink-0">
									<CircleCheck />
								</div>
								Criador de anúncio com IA. Crie o anúncio perfeito e venda
								rápido.
							</li>
							<li className="flex items-start gap-1">
								<div className="flex-shrink-0">
									<CircleCheck />
								</div>
								Destaque no único portal exclusivo para carros elétricos e
								híbridos do Brasil.
							</li>
						</ul>
					</div> */}
				</Card>
			</div>
		</section>
	)
}
